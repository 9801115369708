// todo: rename filename index.ts to index.tsx ?

import React from 'react'
import VirtualTourPage from "../pages/virtual-tour";
import {t} from "../components/Translator";

// on type OR interface - see https://pawelgrzybek.com/typescript-interface-vs-type/
// typed AppContext see https://www.carlrippon.com/react-context-with-typescript-p2/
// export type AppContextType = {...}

export interface IAppContext {
    locale: { lang: string, scrollTop: number }
    setLocale: (locale: { lang: string, scrollTop: number }) => void
    navigationLink: { previous: string, current: string }
    setNewCurrentNavigationLink: (newCurrentNavigationLink: string) => void
    imageModal: { src: string, caption: string }
    setImageModal: (value: { src: string, caption: string }) => void
}

export enum ELocale {
    NL = 'NL',
    FR = 'FR',
    EN = 'EN'
}

export interface ILayout {
    head: { title: object, description: object, keywords: object }
    banner?: { image_src: string, title: object, caption: string|object }
    breadcrumb?: { parentLink: string, parentLinkLabel: object, currentLinkLabel: object }
    carouselImages?: Array<{ src: string, caption?: string|object, sub_caption?: string|object }>
    children: React.ReactNode
}

export interface IBreadcrumb {
    content: { parentLink: string, parentLinkLabel: object, currentLinkLabel: object }
}

export interface ISection {
    content: object
}

export interface ISectionUntranslated {
    html: string
}

export interface IImage {
    className?: string
    image: { src: string, modal: boolean, caption?: object|string }
}

export interface ICarousel {
    images: Array<{ src: string, caption?: string, sub_caption?: string }>
}

export interface IArtworkCarousel {
    images: Array<{ src: string, caption?: string, link?: string }>
    imageHeight: number
}

export interface ICarouselItem {
    image: { src: string, caption?: string, sub_caption?: string }
    active: boolean
}

export interface IArtworkTemplate {
    pageContext: object
}

export interface IArtworkItem {
    artwork: { link: string, thumbnail: string, creator: object, title: object, period: object, material: object }
}

export interface IBanner {
    content: { image_src: string, title: object, caption?: object|string }
}

export enum ENavigationLink {
    Header,
    Footer,
    Logo
}

export interface INavigationLink {
    type: ENavigationLink
    to: string
    label: string
}

export interface IHeaderNavigation {
    hamburgerMenuActive: boolean
}

export interface IHamburgerButton extends IHeaderNavigation {
    toggleHamburgerMenu: () => void
}

export interface IMetadataRow {
    label: object
    value: object
}

export interface IEvent {
    event: {
        day_of_week: object
        day: string
        month: object
        hour: object
        title: object
        abstract: object
        fee: string
        images: []
    }
}




