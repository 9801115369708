// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artworks-tsx": () => import("./../../../src/pages/artworks.tsx" /* webpackChunkName: "component---src-pages-artworks-tsx" */),
  "component---src-pages-concerts-tsx": () => import("./../../../src/pages/concerts.tsx" /* webpackChunkName: "component---src-pages-concerts-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-organs-tsx": () => import("./../../../src/pages/organs.tsx" /* webpackChunkName: "component---src-pages-organs-tsx" */),
  "component---src-pages-virtual-tour-tsx": () => import("./../../../src/pages/virtual-tour.tsx" /* webpackChunkName: "component---src-pages-virtual-tour-tsx" */),
  "component---src-templates-artwork-tsx": () => import("./../../../src/templates/artwork.tsx" /* webpackChunkName: "component---src-templates-artwork-tsx" */)
}

