import React, { useState, useEffect } from 'react'

import { IAppContext, ELocale } from '../types/index'
import { links } from '../constants'

const getInitialNavigationLink = () => {

    // location, document and sessionStorage browser refs fail at 'gatsby build' because browser not yet available at build
    // (as opposed to 'gatsby develop') check undefined or use siteMetadata for url prefix, see:
    //      https://css-tricks.com/how-to-the-get-current-page-url-in-gatsby/
    //      https://www.gatsbyjs.com/docs/overview-of-the-gatsby-build-process/#build-time-vs-runtime

    const path = typeof location !== 'undefined' ? location.pathname : ''
    // console.log('# ContextProvider path ['+path+']')

    // if a non-home url is opened as the 1st of the session, this one must become the current link
    // so that the correct menu-item becomes underlined
    let currentLink = '/'
    links.map((link) => {
        if(path.startsWith(link.to)) currentLink = link.to
    })

    console.log('# ContextProvider getInitialNavigationLink() - path['+path+'] currentLink['+currentLink+']')

    // sessionStorage needed to persist navigationLink after page refreshes
    //      not possible with React Context, useState, useReducer (see doc in backup/ContextProvider)
    // using sessionStorage in stead of localStorage, which is saved after browser exit and not re-fetched at browser launch
    //      clear at browser exit via window.onunload = () => { window.MyStorage.clear() }
    //      https://codesandbox.io/s/smoosh-cherry-thdsm?file=/src/InfoContext.js
    //      https://stackoverflow.com/questions/53453861/react-context-api-persist-data-on-page-refresh
    let result
    if(typeof sessionStorage !== 'undefined') result = JSON.parse(sessionStorage.getItem('navigationLink') as string)
    if(!result) result = { previous: '', current: currentLink }

    console.log('# ContextProvider getInitialNavigationLink() - result['+JSON.stringify(result)+']')

    return result
}

// React Context API with Gatsby examples:
//      https://www.digitalocean.com/community/tutorials/gatsbyjs-state-management-in-gatsby
//      https://www.gatsbyjs.com/blog/2019-01-31-using-react-context-api-with-gatsby/
// React Context API (no Gatsby) example:
//      https://www.savaslabs.com/blog/using-react-global-state-hooks-and-context
//      https://medium.com/better-programming/react-global-component-with-hooks-d1471488cf73
//      https://codesandbox.io/s/react-simple-usecontext-s4mdf?from-embed=&file=/src/index.js
export const AppContext = React.createContext<IAppContext>({} as IAppContext)

const ContextProvider: React.FC = props => {

    // declare all app global variables and hooks to set the variable state
    const [locale, setLocale] = React.useState({ lang: String(ELocale.NL), scrollTop: 0 })
    const [navigationLink, setNavigationLink] = useState(getInitialNavigationLink())
    const [imageModal, setImageModal] = useState({ src:'', caption:'' })

    console.log('# ContextProvider AFTER useState() - navigationLink['+JSON.stringify(navigationLink)+'] locale['+JSON.stringify(locale)+']')

    /**TODO NOT VERY CLEAR 'useEffect dependencies array' - google & read more
     ANY prop that is accessed in useEffect should be declared in the dependencies array
     goal: prevent useEffect calls at re-render component (e.g. via parent re-render) and no change to those dependencies
     https://medium.com/better-programming/understanding-the-useeffect-dependency-array-2913da504c44
     https://egghead.io/lessons/react-manage-the-useeffect-dependency-array
     https://reactjs.org/docs/hooks-faq.html
     dependency array: only call useEffect when imageModal change
     */
    useEffect(() => {
        document.body.style.overflowY = imageModal.src === '' && imageModal.caption === '' ? 'visible' : 'hidden'
        console.log('# ContextProvider - useEffect - window.pageYOffset['+window.pageYOffset+'] locale.scrollTop['+locale.scrollTop+']')
        // (!) scroll fails sometimes for unclear reason, in all methods below tested
        const scrollOptions = { top: locale.scrollTop }
        window.scrollTo(scrollOptions)
        // window.scrollBy(0, locale.scrollTop)
    }, [imageModal, locale])

    const setNewCurrentNavigationLink = (newCurrentNavigationLink: string) => {
        const updateNavigationLink = { previous: navigationLink.current, current: newCurrentNavigationLink }
        sessionStorage.setItem('navigationLink', JSON.stringify(updateNavigationLink))

        console.log('# ContextProvider setNewCurrentNavigationLink() - updateNavigationLink['+JSON.stringify(updateNavigationLink)+']')

        // new navigation > reset local.scrollTop
        setLocale({ lang: locale.lang, scrollTop: 0 })
        setNavigationLink(updateNavigationLink)
    }

    const defaultContext = {
        locale,
        setLocale,
        navigationLink,
        setNewCurrentNavigationLink,
        imageModal,
        setImageModal
    }

    return (
        <AppContext.Provider value={defaultContext}>
            {props.children}
        </AppContext.Provider>
    )
}

// @ts-ignore
export default ({ element }) => (
    <ContextProvider>
        {element}
    </ContextProvider>
)