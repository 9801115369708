// gatsby-browser.js
// see https://www.gatsbyjs.com/docs/browser-apis/

// ES6 import not compatible with JS export.x = require('')
// require('./src/styles/bootstrap.css')
import './src/styles/bootstrap.min.css'
import './src/styles/style.css'
import './src/styles/custom.css'
import './src/styles/color.css'

// Both gatsby-browser.js and gatsby-ssr.js have access to this hook and it’s recommended to wrap both with our provider
//      solution from https://www.digitalocean.com/community/tutorials/gatsbyjs-state-management-in-gatsby
import ContextProvider from './src/components/ContextProvider'

// exports.onInitialClientRender = require('./src/gatsby/browser/onInitialClientRender') // onInitialClientRender DEPRECATED
export const wrapRootElement = ContextProvider