// todo: move all EN/FR translations of labels, titles, captions, etc. to YAML ?

// (!) REMARK: links array order of entries is fixed, changing order will break code (e.g. NavigationLink checks for underline)
module.exports.links = [
    { to: '/', label: { nl: 'Welkom', en: 'Welcome', fr: 'Bienvenue' } },
    { to: '/info', label: { nl: 'Info', en: 'Info', fr: 'Info' } },
    { to: '/events', label: { nl: 'Evenementen', en: 'Events', fr: 'Événements' } },
    { to: '/organs', label: { nl: 'Orgels', en: 'Organs', fr: 'Orgues' } },
    { to: '/concerts', label: { nl: 'Orgelconcerten', en: 'Organ Concerts', fr: 'Concerts d\'orgue' } },
    { to: '/history', label: { nl: 'Historiek', en: 'History', fr: 'Histoire' } },
    { to: '/artworks', label: { nl: 'Kunstwerken', en: 'Works of art', fr: 'Œuvres d\'art' } },
    { to: '/virtual-tour', label: { nl: 'Virtuele tour', en: 'Virtual tour', fr: 'Tour virtuel' } },
    { to: '/contact', label: { nl: 'Contact', en: 'Contact', fr: 'Contact' } }
]

module.exports.church_photography = { nl: '© Kerkfotografie', fr: '© Photographie d\'église', en: '© Church Photography' }

module.exports.no_events_planned = {
    nl: 'Er zijn momenteel geen evenementen gepland.',
    fr: 'Aucun événement n\'est prévu pour l\'instant.',
    en: 'No events are planned at the moment.'
}

module.exports.metadata_labels = {
    creator: { nl: 'Vervaardiger:', en: 'Creator:', fr: 'Créateur:' },
    period: { nl: 'Periode:', en: 'Period:', fr: 'Période:' },
    material: { nl: 'Materiaal:', en: 'Material:', fr: 'Matériau:' },
    dimensions: { nl: 'Afmetingen:', en: 'Dimensions:', fr: 'Dimensions:' },
    origin: { nl: 'Herkomst:', en: 'Origin:', fr: 'Origine:' }
}

module.exports.unknown_creator = { nl: 'vervaardiger onbekend', fr: 'créateur inconnu', en: 'unknown creator' }

module.exports.unknown = { nl: 'onbekend', fr: 'inconnu', en: 'unknown' }

// path = contains full page description with no caps & no special characters, e.g. Israël in:
//      https://www.vrt.be/vrtnws/nl/2020/10/14/israel-keurt-bouw-nieuwe-woningen-op-westelijke-jordaanoever-goe/
module.exports.artworks = [
    { filename: '01_beeldjes_maria_gabriel.yaml', path: 'beeldjes-maria-en-engel-gabriel', type: 0 },
    { filename: '02_doksaal.yaml', path: 'doksaal', type: 1 },
    { filename: '03_grafmonument_carondelet.yaml', path: 'grafmonument-carondelet', type: 3 },
    { filename: '04_kalvarie_catharina_barbara.yaml', path: 'kalvarie-catharina-en-barbara', type: 3 },
    { filename: '05_koorgestoelte.yaml', path: 'koorgestoelte', type: 3 },
    { filename: '06_kromstafbekroning.yaml', path: 'kromstafbekroning', type: 0 },
    { filename: '07_kruisbeeld.yaml', path: 'kruisbeeld', type: 0 },
    { filename: '08_laatste_avondmaal.yaml', path: 'laatste-avondmaal', type: 3 },
    { filename: '09_marteling_hippolytus.yaml', path: 'marteling-hippolytus', type: 3 },
    { filename: '10_passieretabel.yaml', path: 'passieretabel', type: 3 },
    { filename: '11_passietaferelen.yaml', path: 'passietaferelen', type: 3 },
    { filename: '12_retabel_anna.yaml', path: 'retabel-anna', type: 3 },
    { filename: '13_schrijn_karel_de_goede.yaml', path: 'schrijn-karel-de-goede', type: 2 },
    { filename: '14_schrijn_sint_elooi.yaml', path: 'schrijn-sint-elooi', type: 3 },
    { filename: '15_tronende_madonna.yaml', path: 'tronende-madonna', type: 2 },
    { filename: '16_wandtapijtenreeks.yaml', path: 'wandtapijtenreeks', type: 3 },
]

